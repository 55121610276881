import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { errorsReducer as errors } from './errors';
import { sessionReducer as session } from './session';
import { devicesReducer as devices } from './devices';
import { eventsReducer as events } from './events';
import { geofencesReducer as geofences } from './geofences';
import { groupsReducer as groups } from './groups';
import { driversReducer as drivers } from './drivers';
import { availableReducer as available } from './available';
import { maintenancesReducer as maintenances } from './maintenances';
import { reportsReducer as reports } from './reports';
import throttleMiddleware from './throttleMiddleware';
import { usersReducer as users } from './users';
import { cartReducer as cart } from './cart';
import { replayReducer as replay } from './replay';

const reducer = combineReducers({
  errors,
  session,
  devices,
  events,
  geofences,
  groups,
  drivers,
  available,
  maintenances,
  reports,
  users,
  cart,
  replay,
});

export { errorsActions } from './errors';
export { sessionActions } from './session';
export { devicesActions } from './devices';
export { eventsActions } from './events';
export { geofencesActions } from './geofences';
export { groupsActions } from './groups';
export { usersActions } from './users';
export { driversActions } from './drivers';
export { availableActions } from './available';
export { maintenancesActions } from './maintenances';
export { reportsActions } from './reports';
export { cartActions } from './cart';
export { replayActions } from './replay';

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(throttleMiddleware),
});
